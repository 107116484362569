import { useEffect, useState } from 'react';
import StyledImage from '@apps/components/Image';
import { ENABLE_GOOGLE_ADS } from '@apps/config';
import getAds from '@apps/config/API/ads.config';
import dynamic from 'next/dynamic';
import Link from 'next/link';
const AdSense = dynamic<any>(() => import('@apps/components/GoogleAdsSense'), {
  ssr: true,
});
import { useMediaQuery } from 'react-responsive';
import { graphAnalytics } from '@apps/utils/graph_analytics';
import isEmpty from 'lodash/isEmpty';

const AdsContainer: React.FC<any> = ({
  localPlacement,
  adSlot,
  style,
  ads,
  imageStyle,
  checkAdblock,
  containerStyle,
}) => {
  // ======================= HOOKS
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  // ======================= STATE
  const [data, setData] = useState<any>();

  // ======================= API
  const fetchData = async (
    inPlacement:
      | 'xl_listing'
      | 'sm_listing'
      | 'sm_details'
      | 'home'
      | 'news_listing_vertical_left'
      | 'news_listing_vertical_right'
      | 'news_detail_vertical_left'
      | 'news_detail_vertical_right'
      | 'lg_details'
      | 'sm_car_detail'
      | 'xl_car_detail'
  ) => {
    try {
      const res = await getAds(inPlacement);

      graphAnalytics({
        type: 'ads_block_placement_impression',
        id: res?.ads_block_placement_id,
      });

      setData(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  const onPressAdsBlockView = (id: any) => {
    graphAnalytics({
      type: 'ads_block_placement_view',
      id,
    });
  };

  useEffect(() => {
    if (ads) return;
    fetchData(localPlacement);
  }, [localPlacement]);

  if (!ads && !data) return null;
  if (ads && !isEmpty(ads)) {
    return (
      <Link
        href={ads?.ads_block_placement_link}
        target={ads?.ads_block_placement_link_type}
        style={{
          lineHeight: 0,
          width: '100%',
        }}
        onClick={() => onPressAdsBlockView(data?.ads_block_placement_id)}
      >
        <StyledImage
          src={
            isTabletOrMobile
              ? ads?.ads_block_placement_media_mobile_url
              : ads?.ads_block_placement_media_desktop_url
          }
          width={343}
          height={280}
          alt={ads?.ads_block_placement_title}
          imageStyle={imageStyle}
        />
      </Link>
    );
  }

  if (data && !isEmpty(data)) {
    return (
      <Link
        href={data?.ads_block_placement_link}
        target={data?.ads_block_placement_link_type}
        style={{
          lineHeight: 0,
          width: '100%',
          paddingTop: '50px',
          ...containerStyle,
        }}
        onClick={() => onPressAdsBlockView(data?.ads_block_placement_id)}
      >
        <StyledImage
          src={
            isTabletOrMobile
              ? data?.ads_block_placement_media_mobile_url
              : data?.ads_block_placement_media_desktop_url
          }
          width={1}
          height={1}
          imageStyle={imageStyle}
          alt={data?.ads_block_placement_title}
          checkAdblock={checkAdblock}
        />
      </Link>
    );
  }

  if (!ENABLE_GOOGLE_ADS) return;

  return <AdSense adSlot={adSlot} style={{ ...style }} />;
};

export default AdsContainer;
